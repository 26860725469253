<template>
    <div class="introducepro" v-if="isMobile === 1">
        <div class="bgVideo"></div>
        <div class="headerWarp">
            <div class="logo">
                <img src="~/assets/el/logo4.svg" />
            </div>
            <!-- <div class="rightLinkWarp">
                <NuxtLink class="startBtn" to="/login">LOG IN</NuxtLink>
            </div> -->
        </div>
        <div class="bottomWarp">
            <!-- <div class="bigTitle"></div> -->
            <div class="bigTitle">
                Show Your Elegance!
            </div>
            <div class="secondclass">
                Share beautiful black and white photos of yourself.
            </div>
            <div class="thirdclass">
                Explore fashion, get recognized, and rate beauty!
            </div>
            <div class="footercontent" @click="goRegister(); gtag_report_conversion()">START</div>
            <div class="footer">© 2024 {{ runtimeConfig.public.domain }}. All Rights Reserved.</div>
        </div>
    </div>
    <el-pc-home v-if="isMobile === 0" :from="from" />
    <div class="siteLoading" v-if="showSiteLoading">
        <img src="~/assets/el/logo2.svg" />
    </div>
    <site-menu v-if="isMobile === 1" />
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const { gtag } = useGtag();
const userinfo = useCookie("userinfo");
const showSiteLoading = ref(false);
const route = useRoute();
const router = useRouter();
const gtag_report_conversion = ()=> {
    
    typeof gtag == 'function' && gtag("event", "Home_Start_Click")
};

// const { data: isMobile } = await useFetch("/api/init");
// useState("isMobile", () => isMobile);
// const isMobile=useState('isMobile')

const from = ref({
    type: String,
    default: "foryou", // foryou | follow
});
const isMobile = useState("isMobile");
const autoJumpRate = () => {
    showSiteLoading.value = true;
    isMobile.value = navigator?.appVersion.indexOf("Windows") === -1 ? 1 : 0;
    if (isMobile.value) {
        const UUID =
            localStorage && localStorage?.getItem
                ? localStorage.getItem("UUID") || ""
                : "";
        if (UUID) {
            if (route?.query && route.query.notAutoJump == 1) {
                showSiteLoading.value = false;
            } else {
                router.push("/foryou");
            }
        } else {
            showSiteLoading.value = false;
        }
    } else {
        showSiteLoading.value = false;
    }
}
const goRegister = (event) => {
    let navigatorVal = navigator && navigator.userAgent ? navigator.userAgent : '',
        bool = false
    if (navigatorVal.indexOf('FB') > -1) {
        bool = true
    }
    if (navigatorVal.toLocaleLowerCase && navigatorVal.toLocaleLowerCase().indexOf('instagram') > -1) {
        bool = true
    }
    if (navigatorVal.toLocaleLowerCase && navigatorVal.toLocaleLowerCase().indexOf('bytelo') > -1) {
        bool = true
    }
    const isIos = useState("isIos")
    if (bool && !isIos.value) {
        const showOpenBrowseTip = useState('showOpenBrowseTip')
        showOpenBrowseTip.value = true
    } else {
        router.push("/register");
    }
}
onMounted(() => {
    //autoJumpRate()
});
</script>
<style lang="scss" scoped>
.introducepro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url("@/assets/img/bgc.jpg") no-repeat;
    background-size: cover;
    background-color: #0f0f0f;
    color: #fff;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    box-sizing: border-box;
}
.introducepro::after,
.introducepro::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;
}
.introducepro::before {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
    );
    top: 0;
    height: 11.8rem;
}
.introducepro::after {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.6)
    );
    bottom: 0;
    height: 50rem;
}
.introducepro .bgVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #0f0f0f;
    object-fit: cover;
    object-position: top;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('~/assets/el/mobilehomevideoposter.png');
}
.headerWarp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.65rem 1.6rem 0 5.2rem;
    position: relative;
    z-index: 3;
}
.logo {
    width: 171px;
    height: 26px;
    background-size: 100% 100%;
}
.rightLinkWarp {
    margin-top: -0.2rem;
    a {
        color: #fff;
        font-family: Poppins-Medium;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 2rem;
    }
}
.bottomWarp {
    margin: 0 2.8rem 2rem 2.8rem;
    position: relative;
    z-index: 3;
    text-align: center;
    .footer {
        text-align: center;
        margin-top: 2.4rem;
    }
}
.bigTitle {
    font-size: 2.2rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 3.3rem;
    -webkit-text-stroke: 1px #ffffff;
    text-stroke: 1px #ffffff;
    -webkit-background-clip: text;
    font-family: Poppins-SemiBold;
}
.secondclass,
.thirdclass {
    margin-top: 1.6rem;
    font-size: 1.5rem;
    font-family: "Poppins-Medium";
    color: #ffffff;
    line-height: 2.6rem;
    -webkit-background-clip: text;
}

.footercontent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.4rem;
    background-color: #f67128;
    border-radius: 18.7rem 18.7rem 18.7rem 18.7rem;
    margin-top: 2.4rem;
    line-height: 4.4rem;
    font-size: 1.7rem;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.3px;
}
.siteLoading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15rem auto;
    z-index: 999999;
    img {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        margin: auto;
    }
}
.emailLoginLink {
    display: block;
    font-size: 1.7rem;
    line-height: 2.6rem;
    width: fit-content;
    border-bottom: 0.1rem solid #fff;
    color: #fff;
    @extend .publicFont4;
    margin: 2.4rem auto 0;
}
</style>
